import api from '@/api/index'
// axios
import request from '@/utils/request'

// 登录
export function login (data) {
  return request({
    url: api.Login,
    method: 'post',
    data
  })
}

// 用户信息 post 方法
export function getUserInfo (data) {
  return request({
    url: api.UserInfo,
    method: 'post',
    data,
    hideloading: true
  })
}

// 用户名称 get 方法
export function getUserName (params) {
  return request({
    url: api.UserName,
    method: 'get',
    params,
    hideloading: true
  })
}

// 用户名称 get 方法
export function test (data) {
  return request({
    url: api.Test,
    method: 'post',
    data,
    hideloading: true
  })
}

// 通过用户id查询会员信息
export function getVipByUserId (params) {
  return request({
    url: api.GetVipByUserId,
    method: 'get',
    params,
    hideloading: true
  })
}

// 查询报告信息通过用户id
export function getCreditReportByUserId (params) {
  return request({
    url: api.GetCreditReportByUserId,
    method: 'get',
    params,
    hideloading: true
  })
}

// 打开报告
export function getDownLoadUrlByReportIdOpen (params) {
  return request({
    url: api.GetDownLoadUrlByReportIdOpen,
    method: 'get',
    params,
    hideloading: true
  })
}

// 通过报告id获取下载地址 基础版
export function getDownLoadUrlByReportId (params) {
  return request({
    url: api.GetDownLoadUrlByReportId,
    method: 'get',
    params,
    hideloading: true
  })
}

// 通过报告id获取下载地址 升级版
export function getDownLoadUrlByReportIdPlus (params) {
  return request({
    url: api.GetDownLoadUrlByReportIdPlus,
    method: 'get',
    params,
    hideloading: true
  })
}

export function getDownLoadUrlByReportIdPlusFinance (params) {
  return request({
    url: api.GetDownLoadUrlByReportIdPlusFinance,
    method: 'get',
    params,
    hideloading: true
  })
}

// 查询会员订单
export function getBlOrderByUserId (params) {
  return request({
    url: api.GetBlOrderByUserId,
    method: 'get',
    params,
    hideloading: true
  })
}

// 查询会员订单
export function getOrdersByUserId (params) {
  return request({
    url: api.GetOrdersByUserId,
    method: 'get',
    params,
    hideloading: true
  })
}

// 条件查询券
export function getCouponsByModel (params) {
  return request({
    url: api.GetCouponsByModel,
    method: 'get',
    params,
    hideloading: true
  })
}

// 下载pdf
export function downloadPdf (url) {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob'
  })
}

// 查询会员订单
export function getIp () {
  return request({
    url: api.GetIp,
    method: 'get',
    hideloading: true
  })
}

// 查询会员订单
export function getByTel (params) {
  return request({
    url: api.GetByTel,
    method: 'get',
    params,
    hideloading: true
  })
}

// 通过tokenId获取用户信息，有则返回，没有则新增
export function getUserInfoByIdToken (params) {
  return request({
    url: api.GetUserInfoByIdToken,
    method: 'get',
    params,
    hideloading: true
  })
}

// 通过社会统一代码获取公司id
export function getCompanyInfoByCode (params) {
  return request({
    url: api.GetCompanyInfoByCode,
    method: 'get',
    params,
    hideloading: true
  })
}

// 获取用户关注公司
export function getFoucsByUserId (params) {
  return request({
    url: api.GetFoucsByUserId,
    method: 'get',
    params,
    hideloading: true
  })
}

export function updateStatusByUserIdAndCompanyId (data) {
  return request({
    url: api.UpdateStatusByUserIdAndCompanyId,
    method: 'post',
    data,
    hideloading: true
  })
}

// 获取关联用户
export function getRelevancyUser (params) {
  return request({
    url: api.GetRelevancyUser,
    method: 'get',
    params,
    hideloading: true
  })
}

// 新增关联用户
export function addRelevancyUser (data) {
  return request({
    url: api.AddRelevancyUser,
    method: 'post',
    data
  })
}

// 删除关联用户
export function delRelevancyUser (data) {
  return request({
    url: api.DelRelevancyUser,
    method: 'post',
    data
  })
}

// 删除关联用户
export function applyBillRecord (data) {
  return request({
    url: api.ApplyBillRecord,
    method: 'post',
    data
  })
}

// 使用优惠券
export function useCoupon (data) {
  return request({
    url: api.UseCoupon,
    method: 'post',
    data
  })
}

// 刷新idToken
export function refreshToken (data) {
  return request({
    url: api.RefreshToken,
    method: 'post',
    data
  })
}

// 通过code获取 access_token
export function getAccessTokenByCode (params) {
  return request({
    url: api.GetAccessTokenByCode,
    method: 'get',
    params
  })
}

// 获取微信登录参数
export function getWxLoginParam (params) {
  return request({
    url: api.GetWxLoginParam,
    method: 'get',
    params
  })
}

// 修改手机号
export function updateMobile (data) {
  return request({
    url: api.UpdateMobile,
    method: 'post',
    data
  })
}
