<template>
  <div style="background-color: #FFFFFF; padding-bottom: 10px">
    <el-tabs>
      <el-tab-pane label="开具发票">
        <div>
          <el-row>
            <el-col :span="24">
              <div class="i-header">
                <div style="margin-left: 100px; text-align: right;"><span
                  style="height: 40px; display: flex; justify-content: flex-start; align-items: center;">发票类型：</span>
                </div>
                <el-button :type="invoiceType==='plain'? 'primary': ''" @click="invoiceType='plain'">普通发票</el-button>
                <el-button :type="invoiceType==='special'? 'primary': ''" @click="invoiceType='special'">专用发票
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="i-body" v-if="invoiceType==='plain' || invoiceType==='special'">
                <el-form :model="applyInvoiceInfoForm" :rules="plainInvoiceRules" ref="plainForm"
                         label-width="180px"
                         label-position="right">
                  <div>
                    <div style="margin-left: 100px; margin-top: 20px; margin-bottom: 20px; text-align: left;"><span
                      style="font-size: 14px; color: #333333"><strong>发票抬头信息</strong></span></div>
                    <el-form-item prop="titleName" label="单位名称:">
                      <el-input placeholder="请输入单位名称全称" v-model="applyInvoiceInfoForm.titleName"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="taxNum" label="单位税号:">
                      <el-input placeholder="请输入单位税号" v-model="applyInvoiceInfoForm.taxNum"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="companyAddress" label="单位地址:">
                      <el-input placeholder="请输入单位地址" v-model="applyInvoiceInfoForm.companyAddress"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="billPhoneNum" label="联系方式:">
                      <el-input placeholder="请输入联系方式" v-model="applyInvoiceInfoForm.billPhoneNum"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="accountBank" label="开户银行：">
                      <el-input placeholder="请输入开户银行" v-model="applyInvoiceInfoForm.accountBank"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="accountNum" label="银行账户：">
                      <el-input placeholder="请输入银行账户" v-model="applyInvoiceInfoForm.accountNum"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <div style="margin-left: 100px; margin-top: 20px; margin-bottom: 20px; text-align: left;"><span
                      style="font-size: 14px; color: #333333"><strong>接收电子邮箱</strong></span></div>
                    <el-form-item prop="email" label="邮箱地址：">
                      <el-input placeholder="请输入邮箱地址" v-model="applyInvoiceInfoForm.email"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-button type="primary" style="margin: 20px;" @click="handleApply">确认开票</el-button>
                  </div>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-row>
            <el-col :span="24">
              <div class="i-body" v-if="invoiceType==='222'">
                <el-form :model="applyInvoiceInfoForm" :rules="specialInvoiceRules" ref="specialForm"
                         label-width="180px"
                         label-position="right">
                  <div>
                    <div style="margin-left: 100px; margin-top: 20px; margin-bottom: 20px; text-align: left;"><span
                      style="font-size: 14px; color: #333333"><strong>发票抬头信息</strong></span></div>
                    <el-form-item prop="titleName" label="单位名称:">
                      <el-input placeholder="请输入单位名称全称" v-model="applyInvoiceInfoForm.titleName"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="taxNum" label="单位税号:">
                      <el-input placeholder="请输入单位税号" v-model="applyInvoiceInfoForm.taxNum"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="companyAddress" label="单位地址:">
                      <el-input placeholder="请输入单位地址" v-model="applyInvoiceInfoForm.companyAddress"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="billPhoneNum" label="联系方式:">
                      <el-input placeholder="请输入联系方式" v-model="applyInvoiceInfoForm.billPhoneNum"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="accountBank" label="开户银行：">
                      <el-input placeholder="请输入开户银行" v-model="applyInvoiceInfoForm.accountBank"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="accountNum" label="银行账户：">
                      <el-input placeholder="请输入银行账户" v-model="applyInvoiceInfoForm.accountNum"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <div style="margin-left: 100px; margin-top: 20px; margin-bottom: 20px; text-align: left;"><span
                      style="font-size: 14px; color: #333333"><strong>邮寄收件信息</strong></span></div>
                    <el-form-item prop="mailAddress" label="收件地址：">
                      <el-input placeholder="请输入收件地址" v-model="applyInvoiceInfoForm.mailAddress"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="zipCode" label="邮政编码：">
                      <el-input placeholder="请输入邮政编码" v-model="applyInvoiceInfoForm.zipCode"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="receiverName" label="收件人：">
                      <el-input placeholder="请输入收件人" v-model="applyInvoiceInfoForm.receiverName"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="receiverPhoneNum" label="联系电话：">
                      <el-input placeholder="请输入联系电话" v-model="applyInvoiceInfoForm.receiverPhoneNum"
                                style="width: 350px;"></el-input>
                    </el-form-item>
                    <el-button type="primary" style="margin: 20px;" @click="handleApply">确认开票</el-button>
                  </div>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
  <div style="background-color: #FFFFFF; padding-top: 10px; padding-bottom: 10px; margin-top: 10px;">
    <div style="text-align: left; margin-left: 20px;">
      <span><strong>温馨提示：</strong></span>
    </div>
    <div style="text-align: left; margin-left: 40px;">
      <p style="color: #333333;font-size: 14px;">开具发票会在1-2个工作日内完成，普通发票和专用发票均为电子发票，请正确填写邮箱地址。</p>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { applyBillRecord } from '@/api/user.js'

export default {
  name: 'invoice',
  data () {
    var validateEmail = (rule, value, callback) => {
      const regex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!regex.test(value)) {
        callback(new Error('请输入正确的电子邮箱！'))
      } else {
        callback()
      }
    }
    return {
      plainInvoiceRules: {
        titleName: [{
          required: true,
          message: '请输入单位名称全称',
          trigger: 'blur'
        },
        {
          max: 128,
          message: '输入的单位名称全称过长',
          trigger: 'blur'
        }],
        taxNum: [{
          required: true,
          message: '请输入单位税号',
          trigger: 'blur'
        },
        {
          max: 128,
          message: '输入的单位税号过长',
          trigger: 'blur'
        }],
        companyAddress: [{
          required: true,
          message: '请输入单位地址',
          trigger: 'blur'
        },
        {
          max: 256,
          message: '输入的单位地址过长',
          trigger: 'blur'
        }],
        billPhoneNum: [{
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        },
        {
          max: 32,
          message: '输入的联系方式过长',
          trigger: 'blur'
        }],
        accountNum: [{
          required: true,
          message: '请输银行账户',
          trigger: 'blur'
        },
        {
          max: 32,
          message: '输入的银行账户过长',
          trigger: 'blur'
        }],
        accountBank: [{
          required: true,
          message: '请输入开户银行',
          trigger: 'blur'
        },
        {
          max: 128,
          message: '输入的开户银行过长',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '请输入邮箱地址',
          trigger: 'blur'
        },
        {
          required: true,
          validator: validateEmail,
          trigger: 'change'
        }]
      },
      specialInvoiceRules: {
        titleName: [{
          required: true,
          message: '请输入单位名称全称',
          trigger: 'blur'
        },
        {
          max: 128,
          message: '输入的单位名称全称过长',
          trigger: 'blur'
        }],
        taxNum: [{
          required: true,
          message: '请输入单位税号',
          trigger: 'blur'
        },
        {
          max: 128,
          message: '输入的单位税号过长',
          trigger: 'blur'
        }],
        companyAddress: [{
          required: true,
          message: '请输入单位地址',
          trigger: 'blur'
        },
        {
          max: 256,
          message: '输入的单位地址过长',
          trigger: 'blur'
        }],
        billPhoneNum: [{
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        },
        {
          max: 32,
          message: '输入的联系方式过长',
          trigger: 'blur'
        }],
        mailAddress: [{
          required: true,
          message: '请输入收件地址',
          trigger: 'blur'
        },
        {
          max: 256,
          message: '输入的收件地址过长',
          trigger: 'blur'
        }],
        receiverPhoneNum: [{
          required: true,
          message: '请输入联系人电话',
          trigger: 'blur'
        },
        {
          max: 32,
          message: '输入的联系电话过长',
          trigger: 'blur'
        }],
        receiverName: [{
          required: true,
          message: '请输入收件人',
          trigger: 'blur'
        }],
        zipCode: [{
          required: true,
          message: '请输入邮政编码',
          trigger: 'blur'
        },
        {
          max: 16,
          message: '输入的邮政编码过长',
          trigger: 'blur'
        }],
        accountNum: [{
          required: true,
          message: '请输银行账户',
          trigger: 'blur'
        },
        {
          max: 32,
          message: '输入的银行账户过长',
          trigger: 'blur'
        }],
        accountBank: [{
          required: true,
          message: '请输入开户银行',
          trigger: 'blur'
        },
        {
          max: 128,
          message: '输入的开户银行过长',
          trigger: 'blur'
        }]
      },
      applyInvoiceInfoForm: {
        titleName: '',
        taxNum: '',
        companyAddress: '',
        billPhoneNum: '',
        mailAddress: '',
        receiverPhoneNum: '',
        receiverName: '',
        zipCode: '',
        blOrderId: this.$route.query.blOrderId,
        accountNum: '',
        accountBank: '',
        email: '',
        billType: ''
      },
      invoiceType: 'plain'
    }
  },
  methods: {
    handleApply () {
      const data = {
        titleName: this.applyInvoiceInfoForm.titleName,
        taxNum: this.applyInvoiceInfoForm.taxNum,
        companyAddress: this.applyInvoiceInfoForm.companyAddress,
        billPhoneNum: this.applyInvoiceInfoForm.billPhoneNum,
        mailAddress: this.applyInvoiceInfoForm.mailAddress === '' ? '' : this.applyInvoiceInfoForm.mailAddress,
        receiverPhoneNum: this.applyInvoiceInfoForm.receiverPhoneNum === '' ? '' : this.applyInvoiceInfoForm.receiverPhoneNum,
        receiverName: this.applyInvoiceInfoForm.receiverName === '' ? '' : this.applyInvoiceInfoForm.receiverName,
        zipCode: this.applyInvoiceInfoForm.zipCode === '' ? '' : this.applyInvoiceInfoForm.zipCode,
        blOrderId: this.applyInvoiceInfoForm.blOrderId,
        accountNum: this.applyInvoiceInfoForm.accountNum,
        accountBank: this.applyInvoiceInfoForm.accountBank,
        email: this.applyInvoiceInfoForm.email === '' ? null : this.applyInvoiceInfoForm.email,
        billType: this.invoiceType === 'plain' ? '00160001' : '00160002'
      }
      console.log(data)
      applyBillRecord(data).then(result => {
        if (result.code === 0) {
          ElMessage.success({
            message: '申请开票成功！',
            offset: 20
          })
          this.$router.push({ path: '/invoice-list' })
        } else {
          ElMessage.error({
            message: result.msg,
            offset: 20
          }).catch(error => {
            ElMessage.success({ message: error, offset: 20 })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 100px;
}

.i-header {
  width: 600px;
  margin-left: 100px;
  display: flex;
  justify-content: flex-start;
  /*text-align: left;*/
  /*vertical-align: middle;*/
}

.i-body {
  width: 600px;
  margin-left: 100px;
  display: flex;
  justify-content: flex-start;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
</style>
